<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <FullCalendar :options="calendarOptions">
          <template v-slot:eventContent="arg">
            <b>{{ arg.timeText }} </b><br />
            <i>{{ arg.event.extendedProps.nTipoRuta }} | </i>
            <i>{{ arg.event.extendedProps.producto }} | </i>
            <i>{{ arg.event.extendedProps.cargues }} Cargues</i>
          </template>
        </FullCalendar>
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import axios from "axios";
export default {
  components: {
    FullCalendar,
  },

  data() {
    return {
      calendarOptions: {
        plugins: [listGridPlugin, interactionPlugin, timeGridPlugin],
        initialDate: new Date(this.$parent.form.fecha),
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridDay,listDay",
        },
        initialView: "timeGridDay",
        locale: esLocale,
        height: 350,
        events: [],
        eventClick: this.verDet,
      },
    };
  },

  methods: {
    getEvents() {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;
      axios
        .get(
          "/api/hidrocarburos/solicitudes/detSolicitud/calendar/" + idSolicitud,
          {}
        )
        .then((response) => {
          this.calendarOptions.events = response.data;
          this.$parent.cargando = false;
        })
        .catch(function(error) {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    verDet(clickInfo) {
      this.$swal({
        icon: "info",
        title: `Cita.. ${new Intl.DateTimeFormat('es-ES',{dateStyle:'short', timeStyle: 'short'}).format(clickInfo.event.start)}` ,
        html: `
            <p><b>Empresa:</b> ${clickInfo.event.extendedProps.empresa}</p>
            <p><b>Tipo Ruta:</b> ${clickInfo.event.extendedProps.nTipoRuta}</p>
            <p><b>Tipo Producto:</b> ${clickInfo.event.extendedProps.producto}</p>
            <p><b>Cargues:</b> ${clickInfo.event.extendedProps.cargues}</p>`,
      });
    },
  },
  created() {
    this.getEvents();
  },
};
</script>

<style>
.fc-toolbar,
.fc-toolbar.fc-header-toolbar {
  padding: 0px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1px;
}
</style>
